import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import { MarkdownRemark} from '../models/markdown-remark';
import { BasePageData, BasePageFooter, BasePageHeader } from '../helpers/base-page';
import { TitleSection } from '../components/fully-integrated/title-section';
import { LifecycleElement } from '../components/fully-integrated/lifecycle-element';
import ReactMarkdown from 'react-markdown';
import {useState} from 'react';
import Slider from 'infinite-react-carousel';
import styled from 'styled-components';
import {BackgroundFigure} from '../components/shared/background-figure';
import {LifecycleContainer} from '../components/fully-integrated/lifecycle-container';
import {device} from '../models/devices';

interface FullyIntegratedPageData extends BasePageData {
  pageData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    altText: string;
    subTitle: string;
    heroImage: string;
    inputPlaceholder: string;
    buttonText: string;
    subList: Array<{
      listItem: string
    }>;
    subSections: Array<{
      label: string;
      color: string;
      background: string;
      title: string;
      processTitleItems: Array<{
        text: string;
        subTitle: string;
        description: string;
        processSteps: Array<{
          step: {
            popOverTitle?: string;
            popOverText?: string;
            popOverPosition?: 'left' | 'right';
            stepNumber: number;
            stepDisplayNumber?: number;
            icon: string;
            stepTitle?: string;
            stepListItems?: Array<{
              stepListItemName: string;
            }>;
            moduleNames?: Array<string>;
          }
        }>
      }>;
    }>;
  }>;
}

const Label = styled.h2`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 3px;
  display: inline-block;
  margin: 120px 0 10px;
  letter-spacing: 2.1px;
  
  @media screen and ${device.tablet} {
    margin-top: 40px;
    margin-bottom: 16px;
  }
`;

const Header = styled.div`
  color: #4f40ab;
  font-size: 48px;
  font-weight: 500;
  max-width: 630px;
  margin: 0 auto 60px;
  line-height: 1.45;
  text-align: center;

  @media screen and ${device.tablet} {
    font-size: 24px;
    margin-bottom: 33px;
    padding: 0 24px;
  }
`;

const Button = styled.button`
  border: none;
  background: none;
  font-size: 14px;
  color: #7b85a0;
  padding: 16px 34px;
  cursor: pointer;
  border-radius: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  
  &.active {
    color: white;
    background: #157ee5;
  }
  
  &:first-child {
    margin-left: auto;
  }
  
  &:last-child {
    margin-right: auto;
  }
`;

const SliderContainer = styled.div`
  text-align: center;
  margin: 0 auto 64px;
  
  .carousel-item {
    will-change: transform;
  }
`;

const SlideHeader = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: 40px auto 14px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    margin-bottom: 16px;
    padding: 0 24px;
    box-sizing: border-box;
  }
`;

const SlideText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #808384;
  max-width: 676px;
  width: 100%;
  margin: 0 auto 64px;

  @media screen and ${device.tablet} {
    font-size: 12px;
    margin-bottom: 34px;
    padding: 0 24px;
    box-sizing: border-box;
  }
`;

const FullyIntegratedPage = ({data}: {data: FullyIntegratedPageData}): JSX.Element => {

  const sliderRef = React.createRef<{
    slickNext: () => void;
    slickPrev: () => void;
    slickGoTo: (index: number) => void;
    slickPause: () => void;
    slickPlay: () => void;
  }>();

  const [activeSlideIndex, setSlide] = useState(0);

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.pageData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}

      />
      <TitleSection
        title={data.pageData.edges[0].node.frontmatter.title}
        text={data.pageData.edges[0].node.frontmatter.subTitle}
        image={data.pageData.edges[0].node.frontmatter.heroImage}
        alt={data.pageData.edges[0].node.frontmatter.altText}
        list={data.pageData.edges[0].node.frontmatter.subList.map(x => x.listItem)}
        inputPlaceholder={data.pageData.edges[0].node.frontmatter.inputPlaceholder}
        buttonText={data.pageData.edges[0].node.frontmatter.buttonText}
      />
      {
        data.pageData.edges[0].node.frontmatter.subSections.map((section, index) => {
          return (
            <section key={index} style={{marginBottom: 83}}>
              <div style={{textAlign: 'center'}}>
                <Label style={{
                  color: section.color,
                  backgroundColor: section.background
                }}>
                  <ReactMarkdown>{section.label}</ReactMarkdown>
                </Label>
              </div>
              <Header>
                <ReactMarkdown>{section.title}</ReactMarkdown>
              </Header>
              <div style={{display: 'flex', overflow: 'auto', margin: '0 24px'}}>
                {
                  section.processTitleItems.map((item, index) => (
                    <Button
                      key={index}
                      className={activeSlideIndex === index ? 'active' : ''}
                      onClick={() => {sliderRef.current?.slickGoTo(index); setSlide(index);}}
                    >
                      {item.text}
                    </Button>
                  ))
                }
              </div>
              <SliderContainer>
                <Slider
                  ref={sliderRef}
                  slidesToShow={1}
                  arrows={false}
                  virtualList={false}
                  swipe={false}
                  swipeToSlide={false}
                >
                  {
                    section.processTitleItems.map((item, index) => (
                      <div key={index} onDrag={() => false}>
                        <SlideHeader>
                          <ReactMarkdown>{item.subTitle}</ReactMarkdown>
                        </SlideHeader>
                        <SlideText>
                          <ReactMarkdown>{item.description}</ReactMarkdown>
                        </SlideText>
                        <LifecycleContainer>
                          {
                            item.processSteps
                              .map(step => step.step)
                              .sort((a, b) => (a.stepNumber ?? 0) - (b.stepNumber ?? 0))
                              .map((step, j) => (
                                <LifecycleElement
                                  key={j}
                                  icon={step.icon}
                                  title={step.stepTitle}
                                  modules={step.moduleNames?.map(moduleName =>
                                    data.modules.edges.map(m => m.node.frontmatter).find(m => m.name === moduleName)
                                  )}
                                  displayedNumber={step.stepDisplayNumber}
                                  stepNumber={step.stepNumber}
                                  listItems={step.stepListItems?.map(i => i.stepListItemName)}
                                  popoverText={step.popOverText}
                                  popoverTitle={step.popOverTitle}
                                  popoverPosition={step.popOverPosition}
                                />
                              ))
                          }
                        </LifecycleContainer>
                      </div>
                    ))
                  }
                </Slider>
              </SliderContainer>
            </section>
          );
        })
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 18, height: 36}}
          position={{right: 0, top: 1303}}
          style={{
            zIndex: 0,
            borderRadius: 18,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#00e0dd'}
          size={{width: 42, height: 84}}
          position={{right: 0, top: 1650}}
          style={{
            zIndex: 0,
            borderRadius: 42,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#00e0dd'}
          size={{width: 42, height: 84}}
          position={{left: 0, top: 2139}}
          style={{
            zIndex: 0,
            borderRadius: 42,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
        />
        <div style={{
          position: 'absolute',
          left: -10,
          top: 1807,
          zIndex: 1,
          width: 116,
          height: 155,
          overflow: 'hidden',
          transform: 'rotate(113deg)',
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#eaedfd'}
            size={{width: 80, height: 80}}
            position={{right: 20, bottom: -10}}
          />
        </div>
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default FullyIntegratedPage;

export const pageQuery = graphql`
  {
    ...BaseData
    pageData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/fullyIntegratedPage.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            altText
            subTitle
            heroImage
            inputPlaceholder
            buttonText
            subList {
              listItem
            }
            subSections {
              label
              color
              background
              title
              processTitleItems {
                text
                subTitle
                description
                processSteps {
                  step {
                    stepNumber
                    icon
                    stepTitle
                    stepDisplayNumber
                    moduleNames
                    popOverTitle
                    popOverText
                    popOverPosition
                    stepListItems {
                      stepListItemName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
