import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import { InputWithButton } from '../shared/input-with-button';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 204px;

  @media screen and ${device.tablet} {
    padding-bottom: 76px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 670px;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 169px;
  grid-row-gap: 24px;
  grid-template-areas:
    'header image'
    'text image'
    'list image';

  @media screen and ${device.tablet} {
    padding: 115px 24px 0;
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'text' 'list' 'image';
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 0;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #d6e7f8;
  grid-area: text;
  letter-spacing: -0.1px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 500;
  color: #d6e7f8;

  p {
    display: inline-block;
    max-width: calc(100% - 25px);
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    margin-top: 0.3em;
    width: 13px;
    height: 13px;
    margin-right: 12px;
    border-radius: 50%;
    background: radial-gradient(circle,
      rgba(0,197,245,1) 0%,
      rgba(0,197,245,1) 35%,
      rgba(0,197,245,0.34) 35%,
      rgba(0,197,245,0.34) 100%
    );
  }

  @media screen and ${device.tablet} {
    font-size: 14px;
    line-height: 24px;
    
    &:last-child {
      margin-bottom: 40px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  grid-area: image;
  margin-top: 33px;

  @media screen and ${device.tablet} {
    margin-top: 7px;
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  image: string;
  alt: string;
  list: string[];
  inputPlaceholder: string;
  buttonText: string;
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <div style={{gridArea: 'list'}}>
          <ul style={{marginBottom: 18, lineHeight: '2em'}}>
            {
              props.list.map((text, index) => (
                <ListItem key={index}><ReactMarkdown>{text}</ReactMarkdown></ListItem>
              ))
            }
          </ul>
          <InputWithButton
            inputPlaceholder={props.inputPlaceholder}
            buttonText={props.buttonText}
            buttonColor={'green'}
            inputWidth={339}
          />
        </div>
        <Image src={props.image} alt={props.alt} />
      </Wrapper>
    </Container>
  );
};
