import Hexagon from '../../images/hexagon.svg';
import LifecycleBackground from '../../images/lifecycle-background.svg';
import React, {useEffect} from 'react';
import styled from 'styled-components';

const LifecycleFlow = styled.div<{width: number}>`
  width: ${props => props.width + 32}px;
  margin-right: -32px;
  position: relative;
  flex-shrink: 0;
  background: url(${LifecycleBackground}) no-repeat;
`;

export const LifecycleContainer = (props: {
  children?: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
}): JSX.Element => {

  const ref = React.createRef<HTMLDivElement>();

  const singleItemWidth = 2912;

  const scrollHandler = () => {

    if (ref.current) {
      if (ref.current.scrollLeft < singleItemWidth) {
        const last = ref.current.childNodes.item(ref.current.childNodes.length - 1);
        last.remove();
        ref.current.prepend(last);
        ref.current.scrollLeft += singleItemWidth;
        return;
      }
      if (ref.current.scrollLeft > singleItemWidth*2) {
        const first = ref.current.childNodes.item(0);
        first.remove();
        ref.current.append(first);
        ref.current.scrollLeft -= singleItemWidth;
        return;
      }
    }
  };

  useEffect(() => {
    ref.current?.removeEventListener('scroll', scrollHandler);
    ref.current?.addEventListener('scroll', scrollHandler);
  });

  return (
    <React.Fragment>
      <div
        className={'desktop-only'}
        style={{
          background: `url(${Hexagon}) center no-repeat`,
          position: 'relative',
          height: 740,
          width: 833,
          margin: '0 auto'
        }}
      >
        {props.children}
      </div>
      <div ref={ref} className={'mobile-only'} style={{overflow: 'auto', height: 465, display: 'flex'}}>
        <LifecycleFlow width={singleItemWidth}>{props.children}</LifecycleFlow>
        <LifecycleFlow width={singleItemWidth}>{props.children}</LifecycleFlow>
        <LifecycleFlow width={singleItemWidth}>{props.children}</LifecycleFlow>
      </div>
    </React.Fragment>
  );
};
