import React, {useState} from 'react';
import styled from 'styled-components';
import {ModuleInfo} from '../../models/module-info';
import ReactMarkdown from 'react-markdown';
import {ArrowContainer, Popover } from 'react-tiny-popover';
import {DummyComponent} from '../../helpers/dummy-component';
import {HSL} from '../../helpers/hsl';
import {device} from '../../models/devices';

interface Coords {
  top: number;
  left: number;
  angle?: number;
}

const lifecycleConfig: Array<{
  listAlignment: 'vertical' | 'horizontal';
  position: {
    container: Coords;
    icon: Coords;
    number?: Coords;
    text?: Coords;
  }
}> = [
  {
    listAlignment: 'horizontal',
    position: {
      container: {
        top: 109,
        left: 702
      },
      icon: {
        top: 34,
        left: 16,
        angle: 213
      },
      text: {
        top: 75,
        left: 40
      }
    }
  },
  {
    listAlignment: 'horizontal',
    position: {
      container: {
        top: 6,
        left: 560
      },
      icon: {
        top: 0,
        left: 9
      },
      number: {
        top: 61,
        left: 33
      },
      text: {
        top: 0,
        left: 100
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 129,
        left: 443
      },
      icon: {
        top: 19,
        left: 169,
        angle: 62
      },
      text: {
        top: 30,
        left: 0
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 286,
        left: 495
      },
      icon: {
        top: 42,
        left: 189
      },
      number: {
        top: 1,
        left: 211
      },
      text: {
        top: 55,
        left: 0
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 486,
        left: 634
      },
      icon: {
        top: 0,
        left: 0,
        angle: 119
      }
    }
  },
  {
    listAlignment: 'horizontal',
    position: {
      container: {
        top: 594,
        left: 569
      },
      icon: {
        top: 43,
        left: 0
      },
      number: {
        top: 0,
        left: 18
      },
      text: {
        top: 39,
        left: 90
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 650,
        left: 346
      },
      icon: {
        top: 0,
        left: 0,
        angle: 180
      }
    }
  },
  {
    listAlignment: 'horizontal',
    position: {
      container: {
        top: 594,
        left: -161
      },
      icon: {
        top: 43,
        left: 291
      },
      number: {
        top: 0,
        left: 308
      },
      text: {
        top: 39,
        left: 0
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 507,
        left: 67
      },
      icon: {
        top: 0,
        left: 0,
        angle: 241
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 305,
        left: 20
      },
      icon: {
        top: 48,
        left: 0
      },
      number: {
        top: 0,
        left: 11
      },
      text: {
        top: 39,
        left: 95
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 175,
        left: 83
      },
      icon: {
        top: -7,
        left: -7,
        angle: 297
      },
      text: {
        top: 0,
        left: 128
      }
    }
  },
  {
    listAlignment: 'horizontal',
    position: {
      container: {
        top: 6,
        left: -161
      },
      icon: {
        top: 0,
        left: 288
      },
      number: {
        top: 61,
        left: 307
      },
      text: {
        top: 0,
        left: 0
      }
    }
  },
  {
    listAlignment: 'vertical',
    position: {
      container: {
        top: 23,
        left: 340
      },
      icon: {
        top: 0,
        left: 0
      }
    }
  },
];

const mobileLifecycleCoords: Array<{
  container: Coords;
  icon: Coords;
  number?: Coords;
  text?: Coords;
}> = [
  {
    container: {
      top: 345,
      left: 13
    },
    icon: {
      top: 0,
      left: 0,
      angle: -90
    },
    text: {
      top: -17,
      left: 90
    }
  },
  {
    container: {
      top: 42,
      left: 16
    },
    icon: {
      top: 0,
      left: 0
    },
    number: {
      top: -43,
      left: 20
    },
    text: {
      top: 90,
      left: 84
    }
  },
  {
    container: {
      top: 57,
      left: 310
    },
    icon: {
      top: 0,
      left: 0,
      angle: 0
    },
    text: {
      top: 75,
      left: 0
    }
  },
  {
    container: {
      top: 0,
      left: 560
    },
    icon: {
      top: 40,
      left: 0
    },
    number: {
      top: -1,
      left: 21
    },
    text: {
      top: 132,
      left: 0
    }
  },
  {
    container: {
      top: 57,
      left: 860
    },
    icon: {
      top: 0,
      left: 0,
      angle: 0
    }
  },
  {
    container: {
      top: 0,
      left: 1119
    },
    icon: {
      top: 40,
      left: 0
    },
    number: {
      top: -1,
      left: 21
    },
    text: {
      top: 132,
      left: 0
    }
  },
  {
    container: {
      top: 56,
      left: 1326
    },
    icon: {
      top: 0,
      left: 0,
      angle: 0
    }
  },
  {
    container: {
      top: 0,
      left: 1531
    },
    icon: {
      top: 40,
      left: 0
    },
    number: {
      top: -1,
      left: 14
    },
    text: {
      top: 132,
      left: 0
    }
  },
  {
    container: {
      top: 51,
      left: 1803
    },
    icon: {
      top: 0,
      left: 0,
      angle: 0
    }
  },
  {
    container: {
      top: 0,
      left: 2094
    },
    icon: {
      top: 45,
      left: 0
    },
    number: {
      top: 0,
      left: 14
    },
    text: {
      top: 132,
      left: 0
    }
  },
  {
    container: {
      top: 37,
      left: 2260
    },
    icon: {
      top: 0,
      left: 0,
      angle: 0
    },
    text: {
      top: 95,
      left: 0
    }
  },
  {
    container: {
      top: 0,
      left: 2445
    },
    icon: {
      top: 45,
      left: 0
    },
    number: {
      top: 0,
      left: 14
    },
    text: {
      top: 132,
      left: 0
    }
  },
  {
    container: {
      top: 49,
      left: 2733
    },
    icon: {
      top: 0,
      left: 0,
      angle: 0
    },
    text: {
      top: 75,
      left: 0
    }
  },
];

const Item = styled.div<{
  desktopTop?: number;
  desktopLeft?: number;
  mobileTop?: number;
  mobileLeft?: number;
}>`
  position: absolute;
  top: ${props => props.desktopTop}px;
  left: ${props => props.desktopLeft}px;
  
  @media screen and ${device.tablet} {
    top: ${props => props.mobileTop}px;
    left: ${props => props.mobileLeft}px;
  }
`;

const Icon = styled.img<{
  desktopLeft: number;
  desktopTop: number;
  desktopAngle?: number;
  mobileLeft: number;
  mobileTop: number;
  mobileAngle?: number;
}>`
  position: absolute;
  top: ${props => props.desktopTop}px;
  left: ${props => props.desktopLeft}px;
  transform: rotate(${props => props.desktopAngle ?? 0}deg);
  
  @media screen and ${device.tablet} {
    top: ${props => props.mobileTop}px;
    left: ${props => props.mobileLeft}px;
    transform: rotate(${props => props.mobileAngle ?? 0}deg);
  }
`;

const Number = styled.span<{
  desktopLeft?: number;
  desktopTop?: number;
  mobileLeft?: number;
  mobileTop?: number;
}>`
  font-size: 32px;
  font-weight: 600;
  color: white;
  display: block;
  text-align: center;
  width: 33px;
  position: absolute;
  top: ${props => props.desktopTop}px;
  left: ${props => props.desktopLeft}px;
  
  @media screen and ${device.tablet} {
    top: ${props => props.mobileTop}px;
    left: ${props => props.mobileLeft}px;
  }
`;

const ModuleName = styled.span<{color: string;}>`
  color: ${props => HSL.fromHex(props.color).setL(60).toHex()};
  border: 1px solid currentColor;
  border-radius: 2px;
  text-align: center;
  padding: 0 7px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  background: ${props => HSL.fromHex(props.color).setL(95).toHex()};
`;

const TextContainer = styled.div<{
  desktopTop?: number;
  desktopLeft?: number;
  mobileTop?: number;
  mobileLeft?: number;
}>`
  position: absolute;
  top: ${props => props.desktopTop}px;
  left: ${props => props.desktopLeft}px;
  display: grid;
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  @media screen and ${device.desktop} {

    &.vertical {
      grid-template: auto auto / max-content;
    }

    &.horizontal {
      grid-template: auto / auto max-content;
      align-items: center;

      & > .header {
        padding-right: 20px;
        margin-right: 16px;
        padding-top: 15px;
        align-self: start;
        //justify-content: center;
        box-shadow: 26px 0 0 -25px #d6dff8;
      }
    }
  }
  
  @media screen and ${device.tablet} {
    grid-template: auto auto / max-content;
    top: ${props => props.mobileTop}px;
    left: ${props => props.mobileLeft}px;
  }
`;

export const LifecycleElement = (props: {
  icon: string;
  title?: string;
  displayedNumber?: number;
  modules?: (ModuleInfo | undefined)[],
  listItems?: string[];
  popoverTitle?: string;
  popoverText?: string;
  popoverPosition?: 'left' | 'right';
  stepNumber: number;
}): JSX.Element => {

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const PopoverComponent = Popover ?? DummyComponent;

  return (
    <Item
      desktopLeft={lifecycleConfig[props.stepNumber]?.position.container.left}
      desktopTop={lifecycleConfig[props.stepNumber]?.position.container.top}
      mobileLeft={mobileLifecycleCoords[props.stepNumber]?.container.left}
      mobileTop={mobileLifecycleCoords[props.stepNumber]?.container.top}
    >
      <PopoverComponent
        isOpen={
          isPopoverOpen &&
          props.popoverTitle?.length as number > 0 &&
          props.popoverText?.length as number > 0
        }
        padding={5}
        positions={[props.popoverPosition ?? 'right']}
        containerStyle={{zIndex: '10'}}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'#5752c6'}
            arrowSize={10}
            className='popover-arrow-container'
            arrowClassName='popover-arrow'
            arrowStyle={{
              width: 18,
              height: 18,
              background: 'white',
              transform: 'rotate(45deg)',
              border: '1px solid #5752c6',
              zIndex: -1
            }}
          >
            <div style={{
              width: 245,
              borderRadius: 8,
              padding: 15,
              boxSizing: 'border-box',
              backgroundColor: '#5752c6',
              backgroundImage: 'radial-gradient(ellipse at top left, #4f40ab 0%, #4f40ab 70%, #5752c6 70%, #5752c6 100%)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '67% 65%',
              backgroundPositionY: '-70%'
            }}>
              <div style={{
                fontSize: 14,
                color: 'white',
                fontWeight: 600,
                textDecoration: 'underline',
                marginBottom: 6
              }}>{props.popoverTitle}</div>
              <div style={{
                fontSize: 12,
                color: 'white',
                fontWeight: 500,
                lineHeight: '1.5em'
              }}>{props.popoverText}</div>
            </div>
          </ArrowContainer>
        )}
      >
        <Number
          desktopLeft={lifecycleConfig[props.stepNumber]?.position.number?.left}
          desktopTop={lifecycleConfig[props.stepNumber]?.position.number?.top}
          mobileLeft={mobileLifecycleCoords[props.stepNumber]?.number?.left}
          mobileTop={mobileLifecycleCoords[props.stepNumber]?.number?.top}
          onMouseEnter={() => setIsPopoverOpen(true)}
          onMouseLeave={() => setIsPopoverOpen(false)}
        >{props.displayedNumber}</Number>
      </PopoverComponent>
      <Icon
        src={props.icon}
        alt=""
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
        desktopLeft={lifecycleConfig[props.stepNumber]?.position.icon.left}
        desktopTop={lifecycleConfig[props.stepNumber]?.position.icon.top}
        desktopAngle={lifecycleConfig[props.stepNumber]?.position.icon.angle}
        mobileLeft={mobileLifecycleCoords[props.stepNumber]?.icon.left}
        mobileTop={mobileLifecycleCoords[props.stepNumber]?.icon.top}
        mobileAngle={mobileLifecycleCoords[props.stepNumber]?.icon.angle}
      />
      <TextContainer
        desktopLeft={lifecycleConfig[props.stepNumber]?.position.text?.left}
        desktopTop={lifecycleConfig[props.stepNumber]?.position.text?.top}
        mobileLeft={mobileLifecycleCoords[props.stepNumber]?.text?.left}
        mobileTop={mobileLifecycleCoords[props.stepNumber]?.text?.top}
        className={lifecycleConfig[props.stepNumber]?.listAlignment === 'horizontal' ? 'horizontal' : 'vertical'}
      >
        <div className={'header'}>
          {
            (props.modules as ModuleInfo[])?.map((module, index) => (
              <React.Fragment key={index}>
                <ModuleName color={module.color}>{module.name}</ModuleName>
              </React.Fragment>
            ))
          }
          { props.title && (
            <span
              style={{fontSize: 14, fontWeight: 600, marginTop: 8, marginBottom: 4}}
            >
              <ReactMarkdown>{props.title}</ReactMarkdown>
            </span>
          )}
        </div>
        <div>
          <ul style={{listStyle: 'circle', marginLeft: 16, maxWidth: 125, textAlign: 'left'}}>
            {
              props.listItems?.map((text, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: '21px',
                    color: '#7B85A0'
                  }}
                >
                  {text}
                </li>
              ))
            }
          </ul>
        </div>
      </TextContainer>
    </Item>
  );
};
